"use client";
import useSWR from "swr";

import { Product } from "@/types/index";

import { swrFetcher } from "./fetch-client";

const useProduct = () => {
	const productId = process.env.NEXT_PUBLIC_MEMBERSHIP_ID;

	const { data, isLoading } = useSWR<Product>([`/v1/products/${productId}`], swrFetcher);

	return { product: data, productLoading: isLoading };
};

export default useProduct;
