import { sendGTMEvent } from "@next/third-parties/google";
import { useEffect } from "react";

import { useMounted } from "@/lib/hooks/use-mounted";

export const useTrackEdbElement = ({
	element,
	condition = true,
}: {
	element: React.RefObject<HTMLElement>;
	condition?: boolean;
}) => {
	const mounted = useMounted();

	useEffect(() => {
		if (!condition || !mounted) return;

		const currentRef = element.current;
		const handleLinkClick = (event: MouseEvent) => {
			const target: HTMLElement | null = (event.target as HTMLElement).closest("a, .edb-signup");

			if (target) {
				sendGTMEvent({ event: "edb_click", label: target.innerText });
			}
		};

		currentRef?.addEventListener("click", handleLinkClick);

		return () => {
			currentRef?.removeEventListener("click", handleLinkClick);
		};
	}, [element, condition, mounted]);

	return;
};
