"use client";

import Link, { LinkProps } from "next/link";
import { usePathname, useSearchParams } from "next/navigation";

import { useUI } from "@/components/ui/context";

interface LoginLinkProps extends Omit<LinkProps, "href"> {
	children?: React.ReactNode;
	className?: string;
	href?: string;
}

function LoginLink({ children, className, href }: LoginLinkProps) {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const redirectUrl = `${pathname}${searchParams?.size ? `?${searchParams}` : ""}`;
	const loginUrl = `/login/?return_url=${encodeURIComponent(redirectUrl)}`;
	const { closeMenu } = useUI();

	if (pathname === "/login/") {
		return null;
	}

	return (
		<Link
			href={href || loginUrl}
			className={className}
			onClick={() => {
				closeMenu();
				document.body.classList.remove("overflow-hidden");
			}}
			rel="nofollow"
		>
			{children}
		</Link>
	);
}

export { LoginLink };
