"use client";

import { getCookie } from "cookies-next";
import useSWR from "swr";

import { SubscriptionInfo } from "@/types/index";

import { swrFetcher } from "./fetch-client";

export const useSubscription = () => {
	const token = getCookie("token");

	const { data, isLoading } = useSWR<SubscriptionInfo>(
		["/v1/subscription/info", token],
		swrFetcher,
		{ revalidateIfStale: false, refreshInterval: 1000 * 60 * 5 }
	);

	return {
		subscription: data,
		isLoading,
		isEnterpriseUser: data?.name?.includes("Enterprise"),
	};
};
