"use client";

import { getCookie } from "cookies-next";
import useSWR from "swr";

import { swrFetcher } from "@/lib/hooks/fetch-client";
import { Feature } from "@/types/index";

export const useFeatures = () => {
	const token = getCookie("token");
	return useSWR<Feature[]>(["/v1/subscription/features", token], swrFetcher, {
		revalidateIfStale: false,
		refreshInterval: 1000 * 60 * 5,
	});
};
