"use client";

import { useSettings } from "@lib/hooks/use-settings";
import { usePathname } from "next/navigation";

import { useFeatures } from "@/lib/hooks/use-features";
import { access, canAccess } from "@/lib/utils/index";

type AccessType = keyof typeof access;

export function useAccess(accessTypes: AccessType | AccessType[]) {
	const { data: features } = useFeatures();
	const { settings } = useSettings();
	const pathname = usePathname();

	if (settings?.free_access.length && settings?.free_access.includes(pathname)) {
		return true;
	}

	if (Array.isArray(accessTypes)) {
		return accessTypes.every((type) => canAccess(access[type], features));
	}

	return canAccess(access[accessTypes], features);
}

export function useLegacyAccess() {
	const { data: features } = useFeatures();

	// legacy user has access to exactly one of: EDB or SG
	const isLegacyUser =
		canAccess(access.examine_database, features) !== canAccess(access.supplement_guides, features);

	return isLegacyUser;
}
