"use client";

import * as React from "react";

import { useSettings } from "@/lib/hooks/use-settings";
import { useSubscription } from "@/lib/hooks/use-subscription";

interface FreeTrialWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
	fallback?: React.ReactNode;
	cta?: any;
}

type CookieStore = {
	get: (name: string) => Promise<any>;
};

export function FreeTrialWrapper({ children, fallback = null, cta = null }: FreeTrialWrapperProps) {
	const { settings, isLoading: isLoadingSettings } = useSettings();
	const { subscription, isLoading: isLoadingSubscription } = useSubscription();
	const { isEnterpriseUser } = useSubscription();

	const isLoading = isLoadingSettings || isLoadingSubscription;

	React.useEffect(() => {
		if (!isLoading) {
			document.dispatchEvent(new Event("rm:rerun")); // re-run rightmessage after loading
		}
	}, [isLoading]);

	if (isLoading) {
		return fallback;
	}

	if (subscription?.is_active || !settings?.trial || isEnterpriseUser) {
		return cta;
	}

	return children;
}
